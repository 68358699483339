import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 632.000000 649.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,649.000000) scale(0.100000,-0.100000)">
          <path d="M2005 5804 c-92 -8 -203 -30 -315 -63 -43 -13 -121 -60 -99 -61 8 0
53 14 99 30 162 60 359 86 530 70 58 -5 132 -11 165 -14 l60 -6 -49 14 c-88
26 -266 39 -391 30z"/>
<path d="M2590 5710 c80 -26 146 -47 148 -46 1 2 -12 9 -30 16 -18 7 -53 20
-78 30 -44 18 -160 50 -176 50 -5 -1 56 -23 136 -50z"/>
<path d="M2750 5656 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M3737 5628 c11 -13 38 -49 61 -81 l41 -58 16 31 c13 26 21 31 42 27
23 -4 22 -2 -13 34 -37 38 -65 51 -133 63 l-35 6 21 -22z"/>
<path d="M3070 5496 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M3205 5406 c33 -25 65 -49 70 -54 60 -46 207 -153 230 -167 17 -10
39 -26 50 -36 12 -10 70 -25 143 -38 111 -20 280 -67 325 -92 22 -12 22 -5 -4
57 -17 41 -25 50 -41 46 -10 -2 -24 2 -31 11 -13 17 -153 63 -177 58 -18 -4
-80 15 -145 44 -27 13 -61 26 -75 30 -14 4 -30 12 -37 17 -7 5 -17 7 -23 3 -5
-3 -17 1 -26 9 -18 19 -47 21 -41 4 2 -7 9 -13 16 -13 13 0 31 -24 31 -40 -1
-5 -7 -2 -14 7 -7 10 -16 15 -19 11 -4 -3 -7 -1 -7 5 0 7 -7 12 -15 12 -8 0
-15 4 -15 9 0 5 -21 22 -46 38 -26 15 -57 35 -69 43 -125 85 -169 105 -80 36z
m290 -176 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11
-10z"/>
<path d="M4240 5092 c0 -5 4 -12 8 -17 5 -6 24 -53 42 -105 44 -126 55 -148
170 -319 53 -80 111 -171 127 -201 17 -30 46 -82 65 -115 50 -88 88 -189 88
-235 0 -22 4 -40 8 -40 4 0 22 42 40 93 72 207 96 402 68 568 -21 127 -30 152
-56 144 -10 -3 -33 1 -50 10 -18 9 -30 11 -30 5 0 -7 -10 -6 -27 3 -16 8 -70
27 -120 43 -51 15 -93 31 -93 36 0 4 -8 8 -19 8 -10 0 -24 6 -30 14 -7 8 -23
17 -38 21 -14 3 -32 13 -40 20 -8 8 -25 15 -37 15 -14 0 -28 10 -36 24 -13 24
-40 43 -40 28z"/>
<path d="M2677 5024 c-3 -3 -59 -10 -124 -15 -65 -5 -147 -13 -184 -19 -116
-17 -277 -80 -262 -102 6 -11 -16 -68 -27 -68 -5 0 -6 -9 -3 -20 3 -12 -2 -29
-12 -40 -9 -10 -14 -23 -11 -28 7 -12 -34 -162 -45 -162 -5 0 -9 -6 -9 -13 0
-7 -9 -32 -20 -55 -11 -22 -20 -48 -20 -56 0 -21 -22 -66 -32 -66 -4 0 -8 -11
-8 -25 0 -14 -4 -25 -8 -25 -5 0 -9 -6 -10 -12 0 -7 -12 -53 -27 -103 -50
-174 -68 -299 -60 -425 12 -210 16 -235 45 -309 17 -40 30 -80 30 -88 0 -7 7
-16 15 -19 8 -4 15 -14 15 -23 0 -9 18 -36 40 -61 37 -41 40 -49 40 -103 0
-35 4 -56 10 -52 6 4 10 130 10 328 0 293 3 346 25 399 11 29 25 10 26 -35 l1
-42 8 35 c4 19 16 71 25 115 17 81 97 264 158 360 50 80 162 227 199 261 118
111 265 197 413 243 265 83 614 59 814 -56 187 -108 328 -239 446 -416 135
-204 194 -373 216 -625 15 -178 7 -479 -15 -542 -31 -88 -16 -79 60 38 137
209 238 482 259 698 7 72 -9 220 -26 239 -5 5 -9 19 -9 32 0 13 -4 23 -10 23
-5 0 -10 -4 -10 -10 0 -13 -66 -13 -91 1 -11 5 -17 14 -14 20 4 5 -28 9 -79 9
-82 0 -136 13 -136 33 0 5 -15 7 -34 4 -22 -3 -33 -1 -33 6 1 39 -4 57 -14 52
-16 -10 -50 31 -43 52 4 12 3 14 -3 6 -6 -8 -24 7 -58 50 -130 161 -327 324
-480 396 -106 50 -253 92 -371 105 -75 9 -245 -12 -337 -41 -115 -36 -168 -64
-279 -150 -103 -78 -115 -78 -76 2 l22 45 -22 45 c-12 25 -22 51 -22 59 0 16
82 84 133 111 20 11 37 23 37 27 0 10 -16 18 -23 12z"/>
<path d="M4910 4697 c0 -104 22 -152 116 -255 37 -41 109 -137 161 -214 51
-78 93 -138 93 -133 0 11 -141 236 -162 259 -33 36 -19 49 15 14 18 -18 31
-36 30 -39 -4 -10 23 -30 30 -22 11 10 8 25 -3 18 -5 -3 -10 -2 -10 4 0 5 5
13 10 16 6 3 10 12 10 18 0 7 -4 6 -10 -3 -8 -13 -11 -13 -27 3 -16 16 -16 17
0 17 9 0 17 5 17 10 0 6 -4 10 -8 10 -5 0 -15 25 -22 55 -7 30 -21 62 -32 71
-10 10 -18 26 -18 38 0 11 -6 38 -14 61 -8 22 -19 59 -25 82 -7 25 -18 43 -29
46 -10 3 -5 6 13 7 28 2 27 3 -10 11 -22 4 -59 8 -82 8 l-43 1 0 -83z m174
-273 c5 -14 4 -15 -9 -4 -17 14 -19 20 -6 20 5 0 12 -7 15 -16z"/>
<path d="M1445 4760 c-3 -5 -18 -10 -32 -10 -21 0 -23 -3 -14 -14 10 -12 8
-19 -9 -36 -37 -37 -13 -35 34 4 84 68 62 36 -59 -86 -165 -168 -259 -297
-363 -498 -57 -109 -145 -325 -140 -341 2 -6 21 33 43 87 136 345 368 671 620
871 35 28 37 32 18 33 -13 0 -23 -4 -23 -10 0 -5 -9 -10 -21 -10 -11 0 -17 5
-14 10 3 6 -3 10 -14 10 -11 0 -23 -4 -26 -10z"/>
<path d="M1325 4632 c-12 -9 -27 -26 -35 -37 -8 -11 3 -4 23 14 33 32 42 41
35 41 -2 0 -12 -8 -23 -18z"/>
<path d="M1248 4563 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1226 4517 c-11 -12 -26 -30 -34 -39 -8 -10 -11 -18 -6 -17 11 1 68
68 62 74 -2 2 -12 -6 -22 -18z"/>
<path d="M1153 4430 c-3 -11 -12 -20 -19 -20 -8 0 -14 -7 -14 -15 0 -8 -3 -14
-7 -13 -24 5 -43 -17 -43 -47 0 -19 -4 -37 -10 -40 -5 -3 -10 -11 -10 -17 0
-6 10 2 22 18 13 16 22 34 20 40 -1 7 8 20 21 31 31 25 71 83 57 83 -7 0 -14
-9 -17 -20z"/>
<path d="M5375 4410 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M4232 4280 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5450 4285 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M4565 4201 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M836 3699 c-10 -33 -16 -62 -14 -65 3 -2 13 23 22 57 23 79 15 87 -8
8z"/>
<path d="M1471 3594 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M802 3560 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M792 3505 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1461 3464 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1462 3390 c1 -25 7 -52 13 -60 18 -23 56 -131 49 -141 -3 -5 1 -9 8
-9 8 0 22 -20 33 -45 10 -25 29 -57 41 -70 13 -14 20 -25 17 -25 -7 0 44 -70
66 -90 36 -34 96 -115 93 -127 -2 -8 3 -10 13 -7 8 4 15 1 15 -6 0 -6 9 -23
20 -37 11 -14 20 -20 20 -13 0 10 4 9 18 -2 9 -8 25 -18 35 -21 9 -4 17 -11
17 -16 0 -5 21 -17 48 -27 26 -10 58 -24 72 -31 14 -7 33 -13 42 -13 10 0 27
-13 40 -30 12 -16 28 -30 37 -30 8 0 11 -3 8 -7 -7 -7 160 -173 174 -173 13 0
59 -50 59 -65 0 -7 10 -15 23 -18 22 -3 21 -4 -3 -10 -14 -3 -61 -10 -105 -14
l-80 -8 80 -3 c191 -8 539 51 563 95 7 12 33 18 98 24 49 4 112 12 139 17 28
6 84 13 125 17 96 9 116 17 134 54 11 21 40 41 111 76 53 25 115 63 139 83 24
20 70 56 102 79 62 45 164 136 164 147 0 4 -33 -16 -72 -43 -108 -73 -247
-134 -368 -162 -48 -11 -52 -14 -30 -20 24 -7 24 -7 3 -8 -13 -1 -23 -7 -23
-15 0 -8 -10 -22 -21 -30 -13 -9 -19 -21 -16 -30 8 -21 -41 -48 -171 -93
l-107 -38 -195 0 c-132 0 -206 4 -229 13 -40 14 -50 10 -27 -13 24 -24 21 -25
-31 -10 -142 42 -248 91 -296 136 -28 26 -130 74 -197 93 -108 30 -211 100
-322 215 -103 108 -188 215 -188 237 0 7 -3 14 -7 16 -10 4 -73 131 -73 147 0
6 -4 11 -10 11 -5 0 -10 9 -10 19 0 11 -4 23 -10 26 -5 3 -10 21 -10 38 0 18
-5 39 -10 47 -6 10 -9 1 -8 -30z m1308 -990 c0 -13 -20 -13 -40 0 -12 8 -9 10
13 10 15 0 27 -4 27 -10z"/>
<path d="M4281 3298 c-12 -35 -26 -71 -31 -80 -6 -10 -10 -26 -10 -36 0 -49
-153 -311 -249 -427 -53 -64 -190 -192 -247 -230 -32 -22 -65 -46 -73 -53 -21
-21 -230 -119 -301 -142 -59 -19 -305 -64 -389 -72 -46 -4 -48 -19 -10 -63 63
-71 244 -141 389 -150 74 -4 136 6 133 23 -1 6 11 13 27 17 16 3 34 13 41 21
6 8 23 14 38 14 14 1 37 7 51 15 14 8 36 15 50 15 23 1 23 2 5 10 -18 8 -18 9
-2 9 18 1 32 11 68 46 17 17 21 18 29 5 8 -12 11 -11 16 8 4 13 11 21 17 19 6
-2 35 24 65 57 168 192 271 400 347 707 33 134 70 338 61 346 -2 2 -13 -25
-25 -59z"/>
<path d="M4866 2804 c-120 -136 -345 -243 -607 -289 -68 -11 -106 -24 -139
-45 -43 -29 -45 -29 -50 -9 -4 17 -14 7 -56 -53 -75 -107 -165 -201 -243 -253
-38 -25 -74 -45 -80 -45 -6 0 -11 -4 -11 -9 0 -5 -15 -12 -32 -16 -18 -4 -27
-9 -21 -12 7 -2 50 5 95 17 53 14 133 25 223 30 158 8 230 26 371 91 191 88
332 212 461 404 59 89 146 236 141 240 -2 2 -25 -21 -52 -51z"/>
<path d="M3376 739 c-18 -91 -13 -109 30 -109 32 0 37 13 29 71 -5 35 -11 49
-20 45 -7 -2 -17 0 -23 6 -8 8 -12 4 -16 -13z"/>
<path d="M3710 712 c-14 -2 -33 -11 -43 -20 -10 -9 -27 -16 -39 -17 -11 -1
-27 -10 -35 -21 -13 -18 -12 -19 19 -22 29 -3 49 9 60 38 2 5 35 10 73 12 65
3 71 1 80 -19 7 -17 18 -23 42 -23 38 0 39 2 16 40 -13 22 -20 26 -32 18 -12
-7 -32 -6 -66 4 -28 8 -61 12 -75 10z"/>
<path d="M2796 701 c-13 -20 -205 -32 -256 -16 -17 5 -20 2 -20 -24 l0 -31 73
1 c116 1 174 12 197 39 19 21 26 40 16 40 -3 0 -7 -4 -10 -9z"/>
<path d="M2985 695 c-17 -8 -42 -19 -57 -24 -16 -6 -28 -18 -28 -27 0 -11 5
-14 16 -10 9 3 24 6 35 6 12 0 23 10 29 25 9 23 14 25 76 25 78 0 81 5 9 13
-34 4 -61 1 -80 -8z"/>
<path d="M3139 668 c8 -22 57 -46 69 -34 10 10 -13 41 -31 41 -9 0 -19 4 -23
8 -15 14 -23 6 -15 -15z"/>
<path d="M2026 440 c26 -57 25 -294 -1 -353 -4 -10 -3 -17 4 -17 6 0 11 10 11
22 l0 22 15 -20 c10 -15 22 -20 40 -17 14 3 25 9 25 14 0 5 7 6 16 2 11 -4 12
-8 4 -13 -8 -5 -4 -9 9 -13 25 -6 26 0 6 23 -23 26 -34 108 -30 216 5 106 7
124 17 139 4 6 -18 10 -58 10 -53 0 -63 -3 -58 -15z m87 -7 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-2 -50 c-4 -28 -6 -32 -9 -15 -3 22 3 52
11 52 1 0 1 -17 -2 -37z m-54 -20 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2
-21 0 -30z m23 -193 c6 0 10 10 10 23 1 15 5 10 15 -18 18 -48 18 -55 5 -55
-5 0 -10 12 -10 26 0 22 -2 25 -15 14 -12 -10 -15 -10 -15 1 0 11 -3 11 -11
-1 -9 -12 -10 -12 -5 3 3 10 7 53 8 95 l2 77 3 -82 c2 -46 8 -83 13 -83z"/>
<path d="M3010 449 c0 -5 9 -9 20 -9 13 0 20 -7 20 -20 0 -11 4 -20 9 -20 4 0
6 11 4 24 -3 16 -13 26 -29 29 -13 2 -24 0 -24 -4z"/>
<path d="M3162 453 c-48 -3 -61 -7 -54 -16 24 -30 33 -201 15 -294 l-10 -53
48 0 c44 0 47 2 43 23 l-5 22 12 -22 c7 -13 16 -23 22 -23 5 0 4 6 -2 14 -18
21 -22 154 -5 170 8 9 9 17 3 23 -14 14 -30 85 -18 77 5 -3 9 8 9 25 0 16 5
33 10 36 12 7 14 26 3 23 -5 -1 -36 -3 -71 -5z m36 -20 c-10 -2 -26 -2 -35 0
-10 3 -2 5 17 5 19 0 27 -2 18 -5z"/>
<path d="M3321 451 l-23 -6 25 -14 c15 -8 31 -26 36 -40 l10 -26 -1 30 c-1 39
-8 65 -16 64 -4 -1 -18 -4 -31 -8z"/>
<path d="M3596 449 c13 -6 29 -32 39 -62 22 -66 32 -254 16 -288 -12 -23 -12
-23 4 -4 9 11 21 19 26 17 16 -4 47 39 64 90 15 46 15 69 0 113 -22 66 -29 76
-43 70 -10 -3 -12 0 -9 10 4 8 2 17 -3 20 -4 3 -11 -1 -13 -7 -3 -8 -6 -6 -6
5 -1 17 -19 27 -71 41 -23 6 -24 5 -4 -5z m58 -35 c8 -21 8 -24 -3 -24 -5 0
-11 9 -14 20 -6 24 8 27 17 4z m13 -86 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2
-10 2 -28 0 -40z m53 23 c7 -14 7 -22 -2 -27 -6 -4 -8 -3 -4 4 3 6 2 13 -4 17
-10 6 -14 25 -5 25 2 0 9 -9 15 -19z m-20 -76 c0 -14 -2 -25 -4 -25 -2 0 -6
11 -8 25 -3 14 -1 25 3 25 5 0 9 -11 9 -25z m40 -1 c0 -8 -4 -14 -10 -14 -5 0
-10 9 -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z m-7 -44 c-3 -11 -7 -20 -9
-20 -2 0 -4 9 -4 20 0 11 4 20 9 20 5 0 7 -9 4 -20z m-32 -38 c2 -23 0 -45 -4
-48 -4 -4 -7 17 -7 46 0 62 8 64 11 2z"/>
<path d="M1663 431 c17 -20 19 -266 2 -314 -11 -32 -11 -36 1 -26 8 7 14 7 14
1 0 -5 23 -9 54 -8 45 1 52 3 43 15 -17 21 -16 313 1 333 11 14 5 16 -57 14
-58 -1 -67 -3 -58 -15z m80 -8 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35
-2 23 -4z"/>
<path d="M1838 443 c47 -9 72 -111 44 -183 -13 -33 -28 -51 -57 -67 -47 -26
-38 -29 31 -8 132 39 162 190 49 245 -23 11 -52 19 -66 19 -23 -2 -23 -2 -1
-6z m72 -49 c0 -8 4 -14 9 -14 14 0 20 -40 10 -63 -8 -20 -9 -19 -4 5 3 14 0
28 -5 30 -5 2 -14 18 -19 36 -6 20 -6 30 0 27 5 -4 9 -13 9 -21z m40 -94 l-11
-25 6 25 c2 14 5 36 6 50 l2 25 4 -25 c2 -14 -1 -37 -7 -50z m-26 -52 c7 -5 4
-8 -6 -8 -12 0 -18 7 -17 23 0 15 2 17 6 7 3 -8 11 -18 17 -22z m-14 -22 c0
-2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 -2 15 -4z"/>
<path d="M2495 377 c-10 -37 -15 -70 -13 -73 3 -3 11 16 17 42 7 26 16 43 20
38 5 -5 18 -39 31 -76 12 -37 26 -70 31 -73 5 -4 9 -11 9 -17 0 -5 -16 4 -35
21 -36 31 -55 39 -55 22 0 -5 10 -11 23 -14 33 -9 71 -84 65 -131 -3 -32 -2
-35 8 -21 8 11 13 12 16 4 2 -6 28 -14 58 -16 47 -4 53 -2 39 9 -9 7 -29 51
-44 98 -15 47 -31 94 -35 105 -14 36 -30 102 -30 123 0 16 -8 21 -44 23 l-43
4 -18 -68z m45 43 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m47 -17 c1 -24 1 -25 -7 -8 -11 26 -12 35 -2 35 4 0 8 -12 9
-27z m-40 -25 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m30 -90
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m32 -133 l0 -20 -9 20
c-5 11 -9 27 -9 35 -1 13 0 13 9 0 5 -8 9 -24 9 -35z"/>
<path d="M2760 436 c0 -8 -3 -23 -6 -33 -3 -10 1 -7 9 6 8 14 23 28 33 32 15
6 14 8 -8 8 -18 1 -28 -4 -28 -13z"/>
<path d="M2855 440 c17 -4 22 -8 12 -9 -10 0 -15 -6 -12 -11 23 -38 21 -294
-4 -323 -7 -9 8 -12 59 -12 37 0 66 4 63 8 -3 5 -11 38 -19 75 -18 87 -18 180
1 226 8 20 12 36 8 37 -5 0 1 4 12 9 13 6 -9 8 -65 8 -59 0 -76 -3 -55 -8z
m72 -212 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-30 -50 c-3
-7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z"/>
<path d="M3814 431 c15 -19 14 -298 -1 -326 -11 -19 -8 -20 59 -21 50 -1 67 1
59 9 -6 6 -11 26 -11 43 0 18 -4 36 -10 39 -5 3 -9 34 -7 68 1 45 -1 61 -10
59 -7 -1 -13 2 -13 8 0 5 4 10 9 10 4 0 16 27 25 59 11 39 23 61 34 64 9 2
-20 4 -64 3 -69 -1 -80 -3 -70 -15z m44 -8 c-9 -2 -19 -14 -21 -26 -4 -18 -5
-17 -6 6 -1 22 3 27 22 26 15 -1 17 -3 5 -6z m-1 -50 c-3 -10 -5 -4 -5 12 0
17 2 24 5 18 2 -7 2 -21 0 -30z m30 -35 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-20 -50 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-7 -98 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m30 -5 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7
10 -15z"/>
<path d="M3991 443 c51 -8 65 -96 27 -164 -12 -22 -26 -39 -30 -39 -5 0 -6 5
-2 12 5 8 2 9 -10 5 -10 -4 -19 -7 -21 -7 -2 0 6 -11 18 -24 34 -38 48 -56 42
-56 -12 0 35 -76 54 -87 22 -14 61 -4 87 21 15 16 19 30 5 22 -10 -7 -33 19
-55 62 -10 20 -29 38 -46 44 l-29 10 41 31 c24 18 45 45 49 61 17 61 -45 118
-123 115 -28 -2 -29 -3 -7 -6z m66 -55 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m53 -41 c0 -7 -10 -25 -21 -42 -12 -16 -19 -22 -15 -12 4 11
0 17 -12 18 -10 1 -7 4 8 8 19 5 24 13 22 30 -2 15 0 21 7 17 6 -4 11 -13 11
-19z m-50 -61 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z m-27 -63 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m47
-50 c-11 -17 -11 -17 -6 0 4 14 -1 17 -32 19 -37 1 -37 1 -4 5 17 2 32 10 32
16 0 7 5 4 11 -5 7 -13 7 -23 -1 -35z m-10 -51 c0 -7 4 -11 9 -8 11 7 33 -18
24 -27 -12 -13 -22 -7 -17 8 4 10 2 13 -8 10 -14 -6 -38 31 -38 57 0 16 29
-24 30 -40z"/>
<path d="M4273 442 l-51 -3 9 -32 c5 -17 15 -35 23 -39 8 -4 24 -29 37 -55 13
-27 30 -54 38 -61 9 -8 11 -11 5 -8 -6 3 4 -32 22 -77 18 -45 38 -83 44 -85 6
-2 17 14 26 35 9 21 22 52 30 68 23 50 44 116 35 111 -5 -3 -14 -26 -21 -51
-16 -58 -27 -64 -43 -26 -7 17 -17 28 -21 24 -4 -4 -4 0 0 10 4 12 1 17 -12
17 -15 0 -16 2 -5 9 11 7 12 13 4 23 -5 7 -20 41 -33 76 -12 34 -25 63 -29 65
-3 1 -29 1 -58 -1z m61 -28 c5 -13 3 -14 -6 -6 -7 5 -26 12 -43 15 -28 4 -28
4 6 6 26 1 39 -4 43 -15z m-37 -66 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m17 -34 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z
m30 -16 c4 -17 2 -28 -4 -28 -5 0 -10 14 -10 32 0 36 4 35 14 -4z m36 1 c0 -5
-4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m5 -57 c-3
-3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m19 -70 c-14 -10 -15 -16
-6 -19 14 -5 17 -33 3 -33 -11 0 -21 38 -14 56 3 8 9 11 14 8 5 -3 10 5 10 18
1 20 2 20 6 4 3 -12 -2 -25 -13 -34z"/>
<path d="M4597 443 c-44 -4 -50 -7 -63 -38 -17 -41 -17 -48 -4 -40 20 12 15
-229 -5 -261 -13 -21 -12 -21 56 -22 l69 -2 -15 38 c-8 20 -14 44 -13 52 1 8
0 60 -2 115 l-4 100 -6 -70 -6 -70 -2 78 c-1 55 1 76 9 71 6 -3 15 0 20 7 7
11 -2 15 -39 20 l-47 6 53 1 c28 1 52 7 52 12 0 6 -1 9 -2 8 -2 -1 -25 -3 -51
-5z m-17 -280 c-11 -17 -11 -17 -6 0 3 10 7 35 8 55 l1 37 4 -37 c2 -21 -1
-46 -7 -55z m27 35 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m0
-70 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M3498 422 c-16 -9 -28 -20 -28 -24 0 -3 -7 -13 -15 -21 -35 -35 -39
-152 -7 -212 24 -47 47 -68 92 -83 45 -16 54 -15 35 4 -21 21 -41 62 -49 99
-8 39 -10 204 -3 233 3 12 5 22 4 22 -1 0 -14 -8 -29 -18z m6 -27 c-9 -14 -19
-23 -22 -20 -5 4 26 45 35 45 2 0 -4 -11 -13 -25z m-67 -117 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m64 -117 c-13 -8 -13 -12 0 -28 13 -17
13 -17 -6 -3 -11 8 -22 15 -24 17 -8 5 12 23 27 23 14 0 15 -2 3 -9z m27 -42
c-3 -8 -7 -3 -11 10 -4 17 -3 21 5 13 5 -5 8 -16 6 -23z"/>
<path d="M4242 315 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2444 280 c-59 -13 -70 -20 -54 -31 12 -9 11 -10 -4 -5 -24 8 -36 -9
-36 -50 0 -20 6 -34 16 -38 30 -12 44 -6 43 17 -1 17 -2 18 -6 4 -3 -10 -12
-17 -21 -15 -20 4 -28 36 -13 51 7 7 8 4 5 -10 -3 -12 -1 -24 5 -27 7 -4 11 1
11 13 0 12 5 21 10 21 6 0 10 6 10 13 0 8 8 21 18 30 22 20 57 23 46 5 -6 -10
-4 -10 7 0 19 18 -1 30 -37 22z"/>
<path d="M3277 269 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M4242 194 c-2 -83 -4 -92 -24 -101 -19 -8 -14 -10 27 -10 32 -1 43 2
30 7 -17 6 -21 19 -26 101 l-5 94 -2 -91z"/>
<path d="M2456 225 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2 2
-6 -3 -10 -12z"/>
<path d="M2442 160 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2280 132 c0 -10 -3 -22 -7 -26 -5 -4 -8 -13 -8 -19 0 -6 -11 -14
-25 -18 -25 -7 -25 -7 3 -8 30 -1 67 14 79 33 14 20 9 36 -9 29 -12 -5 -15 -2
-10 10 4 11 1 17 -8 17 -8 0 -15 -8 -15 -18z m20 -32 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M2430 117 c0 -8 -10 -19 -22 -25 l-23 -11 23 0 c12 -1 22 4 22 10 0
5 4 8 9 5 5 -4 7 3 4 14 -6 23 -13 26 -13 7z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
